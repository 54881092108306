import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesEarlyProgPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Early progression tips</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_earlyprog.png"
            alt="Early progression tips"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail early progression tips</h1>
          <h2>
            A beginner guide for Honkai: Star Rail that will help you start
            strong in the game!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Video guide" />
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="BXkyk1ZRiQI" />
          </Col>
        </Row>
        <SectionHeader title="Foreword" />
        <p>
          This guide assumes you already known some basic information about
          Honkai: Star Rail - the stats, character classes, elements, skill
          points, relics etc. If none of these terms sound familiar, check our
          Introduction to the game guide that explains everything:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/star-rail/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_introgame.png"
                alt="Introduction to the game"
              />
            }
          />
        </Row>
        <SectionHeader title="What to do after tutorial?" />
        <p>
          It takes roughly 30-60 minutes to finish the tutorial and the game
          actually shows you the basic systems pretty well, but once you unlock
          Warping and do you first pulls, the game opens up and you're finally
          able to do what you want.
        </p>
        <p>
          Still, before you start roaming around the Herta Station, trying to
          find all the chests and lore items, we suggest to focus on continuing
          the Main Story quests. Why?{' '}
          <strong>
            Because despite the fact that you've finished the tutorial, there's
            still a lot of other systems that are locked behind the main story
          </strong>{' '}
          - including Daily Missions, Leveling Rewards, Trailblaze Power
          (stamina) etc.
        </p>
        <h5>Missions</h5>
        <StaticImage
          src="../../../images/starrail/generic/missions.webp"
          alt="Guide"
        />
        <p>
          When you open your Mission log you will be welcomed by a variety of
          missions types to do:
        </p>
        <ul>
          <li>
            <strong>Traiblaze Missions</strong> -{' '}
            <span className="gold">gold</span> color. Those are the missions
            that will push the story forward and if there's one available to do,
            you want to tackle it first,
          </li>
          <ul>
            <li>
              As you can see on the image above, the main story can get gated by
              your Trailblaze Level - that's the moment where you will start
              doing the other missions to get experience and level up,
            </li>
          </ul>
          <li>
            <strong>Companion Missions</strong> -{' '}
            <span className="arrow-purple">purple</span> color. Those are
            missions that explore the backgrounds of some of the characters that
            joined your team. They are more story-heavy and while not as long as
            the Traiblaze missions, they still take some time to do,
          </li>
          <li>
            <strong>Adventure Missions</strong> -{' '}
            <span className="teal">blue</span> color. Those are your typical
            side-missions that don't take much time, but still give you
            experience and some freebies,
          </li>
          <li>
            <strong>Daily Missions</strong> -{' '}
            <span className="green">green</span> color. Daily mission isn't
            visible on the above image, but they are even faster than the
            Adventure ones and you get random ones daily.
          </li>
        </ul>
        <h5>Next steps</h5>
        <p>
          Now that you know about the mission types, let's go back to doing the
          Main Story!
        </p>
        <p>
          The next Traiblazer Mission will introduce Herta and the{' '}
          <strong>Simulated Universe</strong>. Simulated Universe is a roguelike
          game mode where the player will select a team of four Characters to
          venture inside a simulated world to collect a random set of buffs that
          they can use to enhance their team to fight the enemies inside.
        </p>
        <p>You can learn about the mode in a dedicated guide:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Simulated Universe"
            link="/star-rail/guides/simulated-universe"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_sim.png"
                alt="Simulated Universe"
              />
            }
          />
        </Row>
        <p>
          So head to Herta's office and do the trial run of SU. That's the
          moment where you will unlock Herta, who is an AoE specialist and will
          help you a lot in Pure Fiction - sadly, she's not that good in the
          story, so don't invest into her yet. Also, while you can do another
          Simulated Universe run right away, leave it for now and go to the Main
          Story.
        </p>
        <p>
          Not long after you will leave the Herta Station and visit the Astral
          Express. There, you will unlock two new systems:{' '}
          <strong>Operation Briefing and Trailblaze Level rewards</strong>.
        </p>
        <p>
          <strong>Operation Briefing</strong> is basically a set of missions
          that give you various rewards - there are 9 pages in total, each with
          their own missions and main rewards for completing them. Here's how it
          looks like:
        </p>
        <StaticImage
          src="../../../images/starrail/generic/guide_operationbrief.webp"
          alt="Guide"
        />
        <p>
          At the same time you will also unlock{' '}
          <strong>Traiblaze Level rewards</strong>. From now on, every time you
          will level up, you can go back to Pom-Pom to get some freebies!
        </p>
        <StaticImage
          src="../../../images/starrail/generic/tl_reward.webp"
          alt="Guide"
        />
        <p>
          Next, some story stuff will happen and you will end up on a new planet
          - freezing your b... feet off. Thankfully soon after you will
          encounter your first Calyx and{' '}
          <strong>unlock your Traiblaze Power</strong> - which is basically the
          stamina in Honkai: Star Rail.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/guide_calyx.webp"
          alt="Guide"
        />
        <p>
          There are various types of Calyx (Calyxes?), but initially you will be
          only able to farm the Bud of Memories one which gives Character EXP
          Materials. Since your Trailblaze Power will be stuck at 240/240, do a
          few runs in the Calyx, so it starts regenerating again and use the
          materials you've obtained to level up your main team.
        </p>
        <p>
          Now you've unlocked the majority of systems so you can go back to the
          Herta Station and start doing side-missions there or just continue the
          Main Story on Jarilo-VI. Have fun!
        </p>
        <SectionHeader title="Tips and tricks for progression" />
        <p>
          Below you will find a list of tips and tricks that will make your
          early game progression smoother:
        </p>
        <h5>General</h5>
        <ul>
          <li>
            Focus on the Main Story first and only start roaming around when
            you're forced to level up to unlock the next Main Story mission,
          </li>
          <li>
            <strong>Level up your characters to current level cap</strong>. The
            stats you gain make a huge difference, but also the damage formula
            itself is affected by the difference in levels between you and the
            enemy. So if you attack an enemy with a higher level than you, you
            will simply do less damage to him.
          </li>
          <li>
            <strong>
              Equip Light Cones with matching Path to the character or else you
              won't get its passive bonus
            </strong>{' '}
            (only the basic stats bonus),
          </li>
          <li>
            And of course, level up the Light Cone too to the currently
            available level cap,
          </li>
          <li>
            At the same time - <strong>don't spread yourself to thin</strong>.
            The resources you have initially are pretty limited, and you will
            need to farm the EXP Calyx a lot more to get the books,
          </li>
          <ul>
            <li>
              This also applies to upgrading Traces of your characters. Only do
              that for characters you know you will use for a longer time,
            </li>
            <li>
              You can check our Team Building guide to find the best early-game
              characters that are a safe investment:
            </li>
          </ul>
        </ul>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building"
            link="/star-rail/guides/team-building"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_team.png"
                alt="Team building"
              />
            }
          />
        </Row>
        <ul>
          <li>
            <strong>Don't let your Traiblaze Power (stamina) overcap</strong>.
            240 TP is the maximum amount you can store and regenerate over 24h,
          </li>
          <ul>
            <li>
              If you let your stamina overcap, it won't be wasted, but instead
              you will regenerate it slower,
            </li>
          </ul>
          <ul>
            <li>Trailblaze Power is generated at a rate of 1 per 6 minutes,</li>
          </ul>
          <li>
            <strong>
              Wait with your 3 weekly Echo of War runs until the last day before
              reset,
            </strong>
          </li>
          <ul>
            <li>
              You should be able to reach TL 40 in the first 2 weeks (not the
              first week like we initially thought) which means you will be able
              to do higher difficulty EoW - and this will translate to better
              rewards!
            </li>
          </ul>
          <li>
            There's a set of weekly rewards you can obtain in Simulated
            Universe, so make sure to do enough runs to unlock them all,
          </li>
          <li>
            <strong>Don't forget about the Region Shops.</strong> There's a lot
            of important materials and other things available there - even
            Eidolons for the Main Character,
          </li>
          <ul>
            <li>
              You obtain the currency to buy things in the Region Shop by doing
              missions in the zone where the shop is located.
            </li>
            <li>
              There's one Region Shop on each planet - including the Herta
              Station.
            </li>
          </ul>
          <li>
            Always send out your characters on Assignments to gain free
            materials,
          </li>
          <ul>
            <li>
              And don't worry - if you send a character on an Assignments, you
              can still use it in combat,
            </li>
          </ul>
          <li>
            Do your Battle Pass Daily and Weekly missions - the free path
            rewards available there are actually decent,
          </li>
          <li>
            <strong>Explore the world</strong>. Exploration in this game is
            pretty rewarding as each map has tons of Treasures, special enemies,
            puzzles and quests hidden - for completing them you will not only
            get experience, but also Stellar Jades, Relics and various
            Materials,
          </li>
          <li>
            To increase your Equilibrium Level, you need to complete the Trial
            of the Equilibrium missions,
          </li>
          <ul>
            <li>
              At Traiblazer levels 20, 30, 40, 50, 60, and 65, you will unlock a
              mission and after finishing it, the world level will upgrade and
              you will be able to level up your characters past the current cap,
            </li>
          </ul>
          <li>
            <strong>
              At the same time, don't rush the Equilibrium upgrade Missions!
            </strong>{' '}
            If you don't have enough EXP and Ascension materials saved to
            instantly upgrade your characters, you might struggle in the new
            world tier as monsters will become harder to kill.
          </li>
        </ul>
        <h5>Combat</h5>
        <ul>
          <li>
            Build a team with skill point spenders and generators. Spenders are
            your damage dealers and generators are usually supports who don't
            need to use their Skill every turn. If you will build a team with 4
            damage dealers, they will compete for the Skill Points and their
            damage output will be terrible,
          </li>
          <li>
            Always try to match your damage dealers to the weakness of the enemy
            - especially on bosses. The fight will last ages if you're not able
            to break the Toughness Bar,
          </li>
          <li>
            <strong>Become a turtle</strong>. Running a more defensive team is
            not only safer, but actually works better in the early game. Some of
            the bosses have nasty mechanics and without a healer/shielder combo
            in your team, they will slowly whittle your team's HP down before
            you kill them,
          </li>
          <ul>
            <li>
              While in the story you can build a more offensive team, for
              Simulated Universe if you won't go Turtle, you will suffer and
              only waste your time.
            </li>
          </ul>
          <li>
            <strong>Don't hoard your Technique Points</strong>. The items to
            restore them are around every corner, so always try to use a
            Technique to trigger the battle,
          </li>
          <li>
            When using a Technique and make sure to match the element of the
            character the enemy weakness!
          </li>
          <li>
            <strong>Techniques stack, but not all of them</strong>. If a
            Technique gives a buff, you can safely use it one after another and
            then use the last Technique Point on an offensive one to trigger the
            fight,
          </li>
          <ul>
            <li>
              This works wonders before harder fights in Forgotten Hall and
              Simulated World.
            </li>
          </ul>
        </ul>
        <h5>Relics</h5>
        <ul>
          <li>
            <strong>Don't upgrade every Relict piece you find</strong>. The
            materials needed to do that are pretty limited and once you use it
            up, you will need to farm Relics to feed into Relics to upgrade
            them. Which leads to the next point...
          </li>
          <li>
            <strong>
              Don't waste your stamina on farming Relics in the early game!
            </strong>{' '}
            Check the Stamina usage section below for more details about that.
          </li>
        </ul>
        <h5>Warps</h5>
        <ul>
          <li>
            Don't use Stellar Jades to pull on the General Warp. Only use the
            Basic Passes you gain from rewards and missions on that banner and
            save your Stellar Jades for the rate-up banners (or stamina
            refreshes).
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Stamina usage" />
        <p>
          During the first week or two, you will want to focus your Stamina on
          three things:
        </p>
        <ul>
          <li>
            <strong>Golden Calyx: Bud of Memories</strong> and{' '}
            <strong>Golden Calyx: Bud of Aether</strong> to get Character and
            Light Cone EXP Materials,
          </li>
          <li>
            <strong>Crimson Calyx</strong> to get materials needed to level up
            your Traces (so your skills and passives),
          </li>
          <li>
            <strong>Stagnant Shadow</strong> to get materials needed to ascend
            your characters (you need to do it every 10 levels starting from
            level 20, so you will need a lot of materials).
          </li>
        </ul>
        <p>
          Only farm the Golden Calyx when you need the EXP Materials. If your
          main team and their Cones are already at the current level cap, ignore
          the Calyx till you increase it again. Which means your main focus will
          be Traces and Ascension materials.
        </p>
        <p>
          <strong className="red">
            Don't waste your stamina on farming Relics in the early game!
          </strong>
        </p>
        <p>
          Initially in the Cavern of Corrosion you will be only able to farm{' '}
          <span className="hsr-rar rar-3">3★ Relics</span> with a chance to get{' '}
          <span className="hsr-rar rar-4">4★ Relics</span>. Only once you reach
          Difficulty 3 of the Caverns you will have a chance to drop{' '}
          <span className="hsr-rar rar-5">5★ Relics</span> which goes up to 100%
          drop on Difficulty 4 - and you will need a few weeks of playing to
          reach that point.
        </p>
        <p>
          Using some stamina in the Simulated Universe early isn't that bad as
          even <span className="hsr-rar rar-2">2★ Relics</span> and{' '}
          <span className="hsr-rar rar-3">3★ Relics</span> can give you a big
          boost - Simulated Universe is the source of the Planetary Relic Sets
          that just have 2-piece bonuses. Still, once you finish the sets you
          wanted (or when you get the Main Stat you need), wait till you reach
          higher World levels in the Simulated Universe so you can get higher
          Relic rarities.
        </p>
        <p>So here's a summary for stamina usage:</p>
        <ul>
          <li>
            Below TL 40 focus on farming Ascension/Traces materials and
            Character/Cone EXP,
          </li>
          <li>
            Once you reach TL 40, start farming CoC and SU as you've just
            unlocked Gold rarity.
          </li>
        </ul>
        <p>
          To learn more about early game gearing and, check out our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Early gearing"
            link="/star-rail/guides/early-gearing"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_earlygear.png"
                alt="Early gearing"
              />
            }
          />
        </Row>
        <p>
          Check the video below if you want to learn more about stamina usage:
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="arOkVOndu04" />
          </Col>
        </Row>
        <SectionHeader title="How to level up quickly?" />
        <p>
          Is the next Main Story mission a few levels away and you have no idea
          how to raise your Traiblaze Level? Below you will find the sources of
          EXP available to you in Honkai: Star Rail!
        </p>
        <ul>
          <li>
            <strong>Do the Daily Training</strong> - you can receive 1000 EXP a
            day for doing all the missions,
          </li>
          <li>
            <strong>Complete the Adventure and Companion missions</strong> -
            those are actually a great source of additional experience (100-400
            EXP per mission) and on top of it, you will also get some nice
            rewards for doing them,
          </li>
          <li>
            <strong>Spend Traiblaze Power</strong> - whatever you spend your TP
            (stamina) on, you will get a set amount of experience,
          </li>
          <li>
            <strong>Do Operation Briefing</strong> - you will also get EXP for
            doing missions available there,
          </li>
          <li>
            <strong>Explore the Open World</strong> - treasures, monsters,
            random encounters will give you additional experience.
          </li>
          <ul>
            <li>
              While the treasures you can only open once, the monsters respawn
              daily. Which means that after finishing all the other stuff for
              the day and using up your stamina, you can just roam around the
              maps and kill the monsters that respawn daily - they will not only
              give you experience but also Ascension materials!
            </li>
          </ul>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesEarlyProgPage;

export const Head: React.FC = () => (
  <Seo
    title="Early progression tips | Honkai: Star Rail | Prydwen Institute"
    description="A beginner guide for Honkai: Star Rail that will help you start strong in the game!"
  />
);
